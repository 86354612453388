import React from 'react';
import { BrowserRouter , Route, Routes,Navigate,Redirect,HashRouter } from 'react-router-dom';

import Services from './Services';

import Buses from './Buses';
import Navpage from './Navpage'
import Trip from './Trip';
import Stop from './Stop';
import Bussearch from './Bussearch';
import Navpage2 from './Navpage2';
import Navpage1 from './Navpage1';
import Navpage3 from './Navpage3';
import Navpage4 from './Navpage4';
import Navpage5 from './Navpage5';
import Navpage6 from './Navpage6';
import Route1 from './Route1';
import Header from './Header';

import Trainpage from './Trainpage';
import Taxi from './Taxi';
import Partnerinvite from './Partnerinvite';

import Loginpg from './Loginpg';
import Signup from './Signup';
import About from './About'
import Aboutnav from './Aboutnav';
import Loginnav from './Loginnav';
import Partnerinvitenav from './Partnerinvitenav';
import Tripdetails from './Tripdetails';
import Routedetails from './Routedetails';
import Stopdetails from './Stopdetails';
import Busdetails from './Busdetails';
import Details from './Details';
import Cookie from './Cookie';
import Tripbusdetails from './Tripbusdetails';
import Stopbusdetails from './Stopbusdetails';
import Routebusdetails from './Routebusdetails';
import Traindetails from './Traindetails';
import Map from './Map';
import Stopscandetails from './Stopscandetails' 
import Places from './Places';
import Sos from './Sos';
import Wastecollectionlogin from './Wastecollectionlogin';
import Wastecollectionhome from './Wastecollectionhome';
import Footer from './Footer';
const Main = () => {

    return (
      <HashRouter >
     
        <Routes>
        
          <Route component={Navpage} />
          <Route component={Navpage1} />
          <Route component={Navpage2} />
          <Route component={Navpage3} />
          <Route component={Navpage4} />
          <Route component={Navpage5} />
          <Route component={Navpage5} />
          <Route component={Aboutnav} />
          <Route component={Loginnav} />
          <Route component={Partnerinvitenav} />
          <Route component={Details} />
          <Route component={Cookie} />
          <Route component={Map} />
      <Route path="/buses" component={Buses} />
      <Route path="/qr" element={<Stopscandetails />} />

        
        <Route path="/buseshome" element={<Buses />} />
        <Route path="/login" element={<Loginpg />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/channelpartner" element={<Partnerinvite />} />
        <Route path="/about" element={<About />} />
        <Route path="/wsl" element={<Wastecollectionlogin />} />
        <Route path="/wsh" element={<Wastecollectionhome />} />
          <Route path="/" element={<Stop />} />
          <Route path="/route" element={<Route1/>} />
          <Route path="/trip" element={<Trip/>} />
          <Route path="/bus" element={<Bussearch/>} />
          <Route path="/trainpg" element={<Trainpage />} />

          <Route path="/taxipg" element={<Taxi/>} />
          <Route path="/tripdetails" element={<Tripdetails/>} />
          <Route path="/busdetails" element={<Busdetails/>} />
          <Route path="/stopdetails" element={<Stopdetails/>} />
          <Route path="/routedetails" element={<Routedetails/>} />
          <Route path="/tripbusdetails" element={<Tripbusdetails/>} />
          <Route path="/stopbusdetails" element={<Stopbusdetails/>} />
          <Route path="/routebusdetails" element={<Routebusdetails/>} />
          <Route path="/traindetails" element={<Traindetails/>} />
          <Route path="/places" element={<Places/>} />
          <Route path="/sos" element={<Sos/>} />
          <Route path="/services" element={<Services />} />
          <Route path="/footer" element={<Footer/>} />
          <Route path="/header" element={<Header/>} />
          {/* <Route path="*" element={<div>Redirecting to Home
            {console.log('Catch-all route reached')}

          </div>} /> */}

        
        </Routes>
      
      </HashRouter>
    );
  };
  

export default Main