//stops

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Select from 'react-select';
import Map from './Map';
import '../Styles/buses.css';
import Navpage6 from './Navpage6';
import { Link } from 'react-router-dom';
import { css } from '@emotion/react';
import facebook from '../Images/facebook.png';
import linkedin from '../Images/linkedin.png';
import twitter from '../Images/twitter.png';
import youtube from '../Images/youtube.png';
import Cookies from 'js-cookie';
import gmap from '../Images/gmap.png';
import sm from '../Images/streetview.png';
import vd from '../Images/new.jpg'
// import Marquee from 'react-marquee-line'; 
import 'aframe';
import Footer from './Footer'


const Stop = ({ currentLocationName, destinationLocationName }) => {
  const phoneNumber = '+1234567890'; 
  const [typedInput, setTypedInput] = useState('');
  const [activeButton, setActiveButton] = useState(null);
  const [selectedStop, setSelectedStop] = useState('');
  const [stopOptions, setStopOptions] = useState([]);
  const [selectedStopId, setSelectedStopId] = useState('');
  const [defaultSelectValue, setDefaultSelectValue] = useState(null);
  const [nearestStops, setNearestStops] = useState([]);
  const [lastLocationLatitude1, setLastLocationLatitude1] = useState(null);
  const [lastLocationLongitude1, setLastLocationLongitude1] = useState(null);
  
  const [ipLocationLatitude, setIpLocationLatitude] = useState(null);
  const [ipLocationLongitude, setIpLocationLongitude] = useState(null);

  const urlParams = new URLSearchParams(window.location.search);
  const qrslnoFromUrl = urlParams.get('id');
  
  const [foundEntry, setFoundEntry] = useState(null);
 
  const [stopLat, setStopLat] = useState(null);
  const [stopLon, setStopLon] = useState(null);

  const navigate = useNavigate();

  const customStyles1 = {
    control: (provided) => ({
      ...provided,
      width: '300px', // Set the width to your desired value
    }),
    option: (provided, state) => ({
      ...provided,
      color: 'black',
      width: '300px',
    }),
  };
  // const contentContainerStyle = {
  //   paddingBottom: '100px', // Add bottom padding to prevent content from being hidden behind the footer
  // };
  
  const footerContainerStyle = {
    position: 'fixed',
    bottom: 0,
    width: '100%',
    backgroundColor: '#f8f9fa',
    boxShadow: '0 -2px 5px rgba(0, 0, 0, 0.1)',
    zIndex: 1000,
    padding: '10px 20px',
  };
  const handleClick = (buttonName) => {
    setActiveButton(buttonName);
    switch (buttonName) {
      case 'button1':
        navigate('/');
        break;
      case 'button2':
        navigate('/trip');
        break;
      case 'button3':
        navigate('/route');
        break;
      case 'button4':
        navigate('/bus');
        break;
      default:
        break;
    }
  };

  const fetchStops = (input) => {
    const apiUrl = `https://api.busparrot.com/searchstop.php?term=${input}`;
    axios
      .get(apiUrl)
      .then((response) => {
        const options = response.data.map((stop) => ({
          value: stop.id,
          label: stop.value,
        }));
        setStopOptions(options);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  };

  const handleSearch = () => {
    if (selectedStop) {
      navigate('/stopdetails', { state: { from: selectedStop } });
    } else if (defaultSelectValue !== null) {
      navigate('/stopdetails', { state: { from: defaultSelectValue } });
    }
    console.log('Typed Input:', typedInput);
  };

  const getCurrentLocation = async () => {
    try {
      // Case 1: Check Location from Cookies
      const storedLatitude = Cookies.get('latitude');
      const storedLongitude = Cookies.get('longitude');
  
      if (storedLatitude && storedLongitude) {
        console.log('Location from Cookies:', storedLatitude, storedLongitude);
        setLastLocationLatitude1(storedLatitude);
        setLastLocationLongitude1(storedLongitude);
        await fetchNearestStops(storedLatitude, storedLongitude);
      }
      // else {
      //   // Case 2: Fetch Location from Server
      //   const response = await axios.get('https://yatraparrot.com/homephp/checkqrdb.php');
      //   console.log('Response from PHP file:', response.data);
      //   const data = response.data;
      //   console.log(data, 'data');
      //   let matchingEntry;
  
      //   if (Array.isArray(data)) {
      //     matchingEntry = data.find(entry => entry.qrslno === qrslnoFromUrl);
      //   }
  
      //   if (matchingEntry) {
      //     const stopLat = matchingEntry.stop_lat;
      //     const stopLon = matchingEntry.stop_lon;
      //     console.log(stopLat, 'lat');
      //     setStopLat(stopLat);
      //     setStopLon(stopLon);
      //     setLastLocationLatitude1(stopLat);
      //     setLastLocationLongitude1(stopLon);
      //     const expirationDate = new Date();
      //     expirationDate.setFullYear(expirationDate.getFullYear() + 10);
  
      //     Cookies.set('latitude', stopLat.toString(), { expires: expirationDate });
      //     Cookies.set('longitude', stopLon.toString(), { expires: expirationDate });
  
      //     await fetchNearestStops(stopLat, stopLon);
      //   } 
        
      //   else {
      //     console.error('No matching entry found in server data');
      //   }
      // }
    } catch (error) {
      console.error('Error fetching location data:', error);
      // Handle the error case here if needed
    }
  };
  
  





  const fetchLocationByIP = async () => {
    try {
      const ipApiResponse = await axios.get('https://api.ipify.org?format=json');
      const userIpAddress = ipApiResponse.data.ip;

      // Use the ipapi service to get detailed location information without an API token
      const locationApiResponse = await axios.get(`https://ipapi.co/${userIpAddress}/json/`);

      const [latitude, longitude] = [locationApiResponse.data.latitude, locationApiResponse.data.longitude];
      console.log(latitude, "lllll");

      // Set the IP-based location in state
      setIpLocationLatitude(latitude);
      setIpLocationLongitude(longitude);

      const radius = 10;
      fetchNearestStops(latitude, longitude, radius);

      // Note: Don't save the IP-based location in cookies

      console.log(`Latitude set to: ${latitude}`);
      console.log(`Longitude set to: ${longitude}`);
    } catch (error) {
      console.error('Error fetching location by IP:', error);
    }
  };




  
  const fetchNearestStops = async (latitude, longitude) => {
    try {
      console.log(latitude,longitude,"latlon");
      const apiUrl = `https://api.busparrot.com/getneareststops.php?lat=${latitude}&lon=${longitude}&radius=2`;
      console.log(apiUrl,'stops')
      const response = await axios.get(apiUrl);
  
      console.log('API Response:', response);
  
      const nearestStopsData = response.data.stops.map((stop) => ({
        ID: stop.stop_id,
        stop_name: stop.stop_name,
        distance: stop.distance,
        lat: stop.stop_lat,
        lon: stop.stop_lon,
        lbcode:stop.stop_localbodycode,
        page: 'stop',
      }));
  
      setNearestStops(nearestStopsData);
      setDefaultSelectValue({
        value: nearestStopsData[0].ID,
        label: nearestStopsData[0].stop_name,
      });
    } catch (error) {
      console.error('Error fetching nearest stops data:', error);
    }
  };
  

  useEffect(() => {
    getCurrentLocation();


    // const urlParams = new URLSearchParams(window.location.search);
    // const qrslnoFromUrl = urlParams.get('id');
    
    const fetchData = async () => {
      try {
        const response = await axios.get('https://yatraparrot.com/homephp/checkqrdb.php');
        console.log('Response from PHP file:', response.data);
    const data =response.data;
    console.log(data,'data');
    const matchingEntry = data.find(entry => entry.qrslno === qrslnoFromUrl);

if (matchingEntry) {
  // console.log('Matching Entry:', matchingEntry);
  // console.log('Stop Latitude:', matchingEntry.stop_lat);
  // console.log('Stop Longitude:', matchingEntry.stop_lon);
  setFoundEntry(matchingEntry);
} else {
  console.log('No matching entry found for qrslno');
}
       
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    
    fetchData();





    // const marqueeContainer = document.getElementById('marquee-container');
    // const marqueeContent = document.getElementById('marquee-content');

    // if (marqueeContainer && marqueeContent) {
    //   const marqueeWidth = marqueeContent.clientWidth;
    //   const containerWidth = marqueeContainer.clientWidth;
    //   const animationDuration = (marqueeWidth / containerWidth) * 10; // Adjust the factor as needed

    //   marqueeContent.style.animation = `marquee ${animationDuration}s linear infinite`;
    // }
    
  }, []);












  const handleGetDirections = (stop) => {
 
    // Assuming you have the latitude and longitude values for the source and destination
    const destinationLat =stop.lat;
    const destinationLng = stop.lon;
console.log(destinationLat,destinationLng,lastLocationLatitude1,lastLocationLongitude1)
    // Create the Google Maps directions URL
 const directionsURL = `https://www.google.com/maps/dir/${lastLocationLatitude1},${lastLocationLongitude1}/${destinationLat},${destinationLng}`;

    // Open the URL in a new tab
   window.open(directionsURL);
    // const streetViewURL = `https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=${destinationLat},${destinationLng}&heading=0&pitch=0`;

    // Open the URL in a new tab
    // window.open(streetViewURL);
  };


  const handleGetDirections1 = (stop) => {
 
    // Assuming you have the latitude and longitude values for the source and destination
    const destinationLat =stop.lat;
    const destinationLng = stop.lon;
console.log(destinationLat,destinationLng,lastLocationLatitude1,lastLocationLongitude1)
    // Create the Google Maps directions URL
//  const directionsURL = `https://www.google.com/maps/dir/${lastLocationLatitude1},${lastLocationLongitude1}/${destinationLat},${destinationLng}`;

    // Open the URL in a new tab
  //  window.open(directionsURL);
     const streetViewURL = `https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=${destinationLat},${destinationLng}&heading=0&pitch=0`;

    // Open the URL in a new tab
     window.open(streetViewURL);
  };


// const handleGetDirections = (stop) => {
//   console.log(stop);

//   // Assuming you have the distance value for the destination
//   const destinationDistance = stop.distance;

//   console.log(destinationDistance, lastLocationLatitude1, lastLocationLongitude1);

//   // Create the Google Maps directions URL
//   const directionsURL = `https://www.google.com/maps/dir/${lastLocationLatitude1},${lastLocationLongitude1}/${destinationDistance}`;

//   // Open the URL in a new tab
//   window.open(directionsURL);
// };
const renderPlaces = () => {
  if (nearestStops.length === 0) {
    return (
      <div>
        <p style={{ color: 'red', fontSize: '20px', marginLeft: '30px'  }}>
          The public transport system in your area is not digitized yet. Please contact busparrot through your local body to digitize.</p>
      </div>
    );
  }
};

  return (
    <div>
      
    <Navpage6/>
      <div className="subbody container-fluid">
  
      
      <div className="button-container">
   
        <button
            className={`button ${activeButton === 'button1' ? 'active' : ''}`}
            onClick={() => handleClick('button1')}
            style={{ backgroundColor: '#76c045' }}
          >
            Stop
          </button>
          <button
            className={`button ${activeButton === 'button2' ? 'active' : ''}`}
            onClick={() => handleClick('button2')}
          >
            Trip
          </button>
          <button
            className={`button ${activeButton === 'button3' ? 'active' : ''}`}
            onClick={() => handleClick('button3')}
          >
            Route
          </button>
          
          <button
            className={`button ${activeButton === 'button4' ? 'active' : ''}`}
            onClick={() => handleClick('button4')}
          >
            Bus
          </button>
        </div>
       <br/>
{/* changed */}
        <div className="nearest-stops">
        {renderPlaces()}
        {nearestStops.length > 0 && (
              <div style={{textAlign:'center'}} >
          <h2 style={{color:'black',textAlign:'center'}} ><b>Nearest Stops</b></h2>
        
            
                <table style={{ textAlign: 'center' }} className="table-container container-fluid">
                  <thead>
                    <tr>
                      <th style={{color:'black',width:'500px'}} >Stop Name</th>
                      <th style={{color:'black',width:'400px'}} >Distance (km)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {nearestStops.slice(0, 5).map((stop) => (
                      <tr key={stop.ID}>
                        <td style={{color:'black',width:'200px'}} >{stop.stop_name}</td>
                        <td style={{color:'black',width:'200px'}} >
        {/* {typeof stop.distance === 'number' ? stop.distance.toFixed(2) + ' Km away' : 'N/A'} */}

        
        {isNaN(Number(stop.distance))
          ? 'Distance N/A'
          : Number(stop.distance).toFixed(2) + ' Km away'}
          <a onClick={() => handleGetDirections(stop)}>
                          <img
                            src={gmap}
                            alt="Google Maps Icon"
                            style={{ width: '20px', height: '20px', marginRight: '10px' }}
                          />
                        </a>
                        <span className="go">Go </span>
                        <a onClick={() => handleGetDirections1(stop)}>
                          <img
                            src={sm}
                            alt="Google Maps Icon"
                            style={{ width: '35px', height: '30px', marginRight: '10px',marginLeft:'10px' }}
                          />
                        </a>
    
      </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
          <div className="trip-container">
          <h2>Search Stop</h2>
          <div className="input-container ">
            <Select
             
              
              value={selectedStop || defaultSelectValue}
              onChange={(value) => {
                setSelectedStop(value);
                setSelectedStopId(value.ID);
                setTypedInput(value ? value.label : '');
                fetchStops(value ? value.label : '');
              }}
              onInputChange={(inputValue) => {
                setTypedInput(inputValue);
                fetchStops(inputValue);
              }}
              options={stopOptions}
              placeholder="Select Stop"
              defaultValue={null}
              styles={customStyles1}
            />
          </div>

          <div className="input-container">
            <button className="search-button" onClick={handleSearch}>
              Search
            </button>
          </div>
        </div>
       
      </div>






      <div className="mapstyle" style={{ marginBottom: '20px' }}>
  <Map />
</div>
      <br /><br/>
      <br/>
      <br/>
      <div style={{ textAlign: 'center', marginBottom: '750px' }}>
        <a href="https://m.facebook.com/100063656121677/">
          <img className="imagesocial" style={{ width: '30px' }} src={facebook} alt="Facebook" />
        </a>
        <a href="https://twitter.com/BusParrot?s=08">
          <img className="imagesocial" style={{ width: '30px' }} src={twitter} alt="Twitter" />
        </a>
        <a href="https://youtube.com/@user-mk1uk6sq2t">
          <img className="imagesocial" style={{ width: '30px' }} src={youtube} alt="YouTube" />
        </a>
        <a href="https://www.linkedin.com/company/busparrot/">
          <img className="imagesocial" style={{ width: '30px' }} src={linkedin} alt="LinkedIn" />
        </a>
      </div> 


      <div>
  {nearestStops.length > 0 && (
    <div >
      <Footer nearestStops={nearestStops} />
    </div>
  )}
</div>

      
      {/* <div className='mapstyle'><Map/></div> */}
    <br/>
     {/* Marquee container */}
   

     <div className="vr-viewer">
  <a-scene embedded>
    <a-sky src={vd}></a-sky> 
    </a-scene>
</div> 

      









    </div>
  );
};

export default Stop;