import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Details from './Details';
import axios from 'axios';
import '../Styles/details.css';
import eyes from '../Images/eyes.png';
import redparrot from '../Images/redParrot.ico';
import laststep from '../Images/laststep.png';

const Tripbusdetails = () => {
  const location = useLocation();
  const { route, bus ,id} = location.state;
  const [tripDetails, setTripDetails] = useState(null);

  const divStyle = {
    textAlign: 'center',
    fontFamily: 'Comfortaa, sans-serif', // Specify Comfortaa as the font
  };
  console.log({id},"lll")

  useEffect(() => {
    // Define the URL with from.value and to.value
    const apiUrl = `https://api.busparrot.com/gettripdetails.php?tripid=${id}`;

    const fetchData = async () => {
      try {
        // Fetch trip details using Axios and await the response
        const response = await axios.get(apiUrl);
        // Set the trip details in the state
        setTripDetails(response.data);
        console.log(response)
      } catch (error) {
        console.error('Error fetching trip details:', error);
      }
    };

    fetchData();
  }, [id]);

  return (
    <div className='bg-body-tertiary mb-3'>
      <Details />
      <div style={divStyle} className="bg1">
        <h6 className='hstyle'>
          <b>{route}</b>
        </h6>
        <h6 className='hstyle1'><b>to</b></h6>
        <h6 className='hstyle1'>
          <b>{bus}</b>
        </h6>
      </div>
      <div className='sa'>
  {tripDetails && tripDetails.success === 1 && (
    <div className="trip-list">
      {tripDetails.result.map((trip, index) => (
        <div className='det' key={trip.Trip_id}>
        <img className='image1' src={index === tripDetails.result.length - 1 ? laststep : redparrot} alt="BusParrot" />
      
          <div className="trip-details">
            <p> <b>{trip.arrival_time} Arrival</b></p>
            <h6><b> {trip.stop_name}</b></h6>
           
            <p><b>{trip.departure_time} Departure</b></p>
          </div>
        </div>
      ))}
    </div>
  )}
</div>

    </div>
  );
  
 
};

export default Tripbusdetails;
