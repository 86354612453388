import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Details from './Details';
import axios from 'axios';
import '../Styles/details.css';
import eyes from '../Images/eyes.png';
import { useNavigate } from 'react-router-dom';
import like from '../Images/like.png';
import dislike from '../Images/dislike.png';
const Routedetails = () => {
  const location = useLocation();
  const { route } = location.state;
  const [tripDetails, setTripDetails] = useState([]);
  const nav=useNavigate();

  const divStyle = {
    textAlign: 'center',
    fontFamily: 'Comfortaa, sans-serif',
  };

  useEffect(() => {
    const apiUrl = `https://api.busparrot.com/getroutetrips.php?routeid=${route.value}`;

    const fetchData = async () => {
      try {
        const response = await axios.get(apiUrl);
        console.log(response);
        setTripDetails(response.data.result);
      } catch (error) {
        setTripDetails(error.response.data.result);
        console.error('Error fetching trip details:', error);
      }
    };

    fetchData();
  }, [route.value]);
  const handleclick = (trip) => {
console.log(trip.trip_id)
    nav('/routebusdetails', { state: { route: trip.trip_headsign, bus: trip.Bus,id:trip.trip_id } })
  }

  const handlemessage = async (trip) => {
    const ipApiResponse = await axios.get('https://api.ipify.org?format=json');
    const userIpAddress = ipApiResponse.data.ip;
    const currentTime = new Date(); 
    const feedbackData = {
      appname: 'Bus',
      category: 'Route',
      infoid: "tripid : "+trip.TripID,
      customerid: 'Not defined',
      ipaddress: userIpAddress,
      feedback: 'Correct',
      message: 'Not defined',
      time: currentTime.toLocaleString(),
    }

    const apiUrl = 'https://yatraparrot.com/feedback/customer_feedback.php';
    const urlWithParams = `${apiUrl}?appname=${feedbackData.appname}&category=${feedbackData.category}&infoid=${feedbackData.infoid}&customerid=${feedbackData.customerid}&ipaddress=${feedbackData.ipaddress}&feedback=${feedbackData.feedback}&message=${feedbackData.message}&time=${feedbackData.time}`;
    
  
    try {
      const response = await fetch( urlWithParams, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // Add any other headers as needed
        },
        body: JSON.stringify({
          // Add your data here
          
          // Add other data fields as needed
        }),
      });
  
      console.log(response, "feedback response");


      if (response.status === 200) {
        // Display an alert when feedback is successfully posted
        window.alert('Feedback posted successfully!');
      } else {
        // Display an alert for any other response status
        window.alert('Failed to post feedback. Please try again.');
      }
      // Handle the response accordingly
    } catch (error) {
      console.error('Error sending feedback:', error);
    }
  };
  const smallButtonStyle = {
    width: '40px'
  };



  const handlemessage1 = async (trip) => {
    const ipApiResponse = await axios.get('https://api.ipify.org?format=json');
    const userIpAddress = ipApiResponse.data.ip;
    const currentTime = new Date();
  
    // Show a prompt to enter the message
    const userMessage = window.prompt('Enter your feedback message:');
  
    // Check if the user clicked cancel
    if (userMessage === null) {
      window.alert('Feedback submission canceled.');
      return;
    }
  
    const feedbackData = {
      appname: 'Bus',
      category: 'Route',
      infoid: 'tripid' + trip.TripID,
      customerid: 'Not defined',
      ipaddress: userIpAddress,
      feedback: 'InCorrect',
      message: userMessage || 'No Messages', // Use user input or 'No Messages' if empty
      time: currentTime.toLocaleString(),
    };
  
    const apiUrl = 'https://yatraparrot.com/feedback/customer_feedback.php';
    const urlWithParams = `${apiUrl}?appname=${feedbackData.appname}&category=${feedbackData.category}&infoid=${feedbackData.infoid}&customerid=${feedbackData.customerid}&ipaddress=${feedbackData.ipaddress}&feedback=${feedbackData.feedback}&message=${feedbackData.message}&time=${feedbackData.time}`;
  
    try {
      const response = await fetch(urlWithParams, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // Add any other headers as needed
        },
        body: JSON.stringify({
          // Add your data here
          // Add other data fields as needed
        }),
      });
  
      console.log(response, 'feedback response');
  
      if (response.status === 200) {
        // Display an alert when feedback is successfully posted
        window.alert('Feedback posted successfully!');
      } else {
        // Display an alert for any other response status
        window.alert('Failed to post feedback. Please try again.');
      }
      // Handle the response accordingly
    } catch (error) {
      console.error('Error sending feedback:', error);
    }
  };
  
  const smallButtonStyle1 = {
    width: '30px', // Adjust the width value as needed
   
    
    // backgroundColor: ' #f72e42',
    marginLeft: '10px',
    marginTop:'8px'
  };
  
  return (
    <div className='bg-body-tertiary mb-3'>
      <Details />
      <div style={divStyle} className="bg1">
        <h6 className='hstyle'>
          <b>{route.label}</b>
        </h6>
      </div>
    
      {tripDetails && Array.isArray(tripDetails) && tripDetails.length > 0 ? (
  <div className="trip-list">
    {tripDetails.map((trips) => (
      <div className='det'>
          <img className='image' src={eyes} alt="BusParrot" /> 
      <div className="trip-details" key={trips.TripID}>
      
        {/* Render your trip details here */}
       
        <div style={{ display: 'flex', justifyContent: 'right', alignItems: 'right' }}>
         
         <div onClick={() => handlemessage(trips)}>
         <h7>Was this Correct?</h7>
           <img src={like} alt="Correct" style={smallButtonStyle} />
         </div>
         <div onClick={() => handlemessage1(trips)}>
           <img src={dislike} alt="InCorrect" style={smallButtonStyle1} />
         </div>
       </div>
        <p><b>{trips.arrivaltime} Arrival</b></p>
        <h6><b>{trips.trip_headsign}</b></h6>
        <span className='sp' onClick={() => handleclick(trips)}><b>{trips.Bus}</b></span>
        <p><b>{trips.departuretime} Departure</b></p>
        {/* <button style={smallButtonStyle} onClick={() => handlemessage(trips)}>Correct</button>
        <button style={smallButtonStyle1} onClick={() => handlemessage1(trips)}>InCorrect</button> */}


        </div>
      </div>
    ))}
  </div>
) : (
  <p>No trip details available</p>
)}
     
    </div>
  );
};

export default Routedetails;
