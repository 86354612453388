import React, { useEffect, useState } from 'react';
import axios from 'axios';
import busparroticon from '../Images/busparrotlogo.png';
import { Link } from 'react-router-dom';
import { Navbar, Nav, Container } from 'react-bootstrap';

const Footer = ({ nearestStops }) => {
  const [lbName, setLbName] = useState('');
  const [lbNoticeboardUrl, setLbNoticeboardUrl] = useState('');
  const [message, setMessage] = useState('');
  const [cookieNames, setCookieNames] = useState([]);

  useEffect(() => {
    console.log('Nearest Stops from footer:', nearestStops);
  }, [nearestStops]);

  // Extract lbcode and page from nearestStops
  const firstLbcode = nearestStops.length > 0 ? nearestStops[0].lbcode : null;
  const page = nearestStops.length > 0 ? nearestStops[0].page : '';

  // Fetch data from PHP script
  const sendDataToPHP = async () => {
    try {
      // Check if the page exists and get the message
      const checkResponse = await axios.post('https://busparrot.com/home/db/messages.php?action=check', {
        lbcode: firstLbcode,
        page: page
      });
      console.log('Check response from PHP:', checkResponse.data);

      if (checkResponse.data && checkResponse.data.message) {
        setMessage(checkResponse.data.message);
      } else {
        console.error('');
      }

      // Fetch additional data
      const fetchResponse = await axios.post('https://busparrot.com/home/db/footer.php?action=fetch', {
        lbcode: firstLbcode
      });
      console.log('Fetch response from PHP:', fetchResponse.data);

      if (fetchResponse.data.lb_name) {
        setLbName(fetchResponse.data.lb_name);
      }
      if (fetchResponse.data.lb_noticeboardurl) {
        setLbNoticeboardUrl(fetchResponse.data.lb_noticeboardurl);
      }
    } catch (error) {
      console.error('Error sending data to PHP:', error);
    }
  };

  useEffect(() => {
    if (firstLbcode && page) {
      sendDataToPHP();
    }
  }, [firstLbcode, page]);

  useEffect(() => {
    const marqueeContainer = document.getElementById('marquee-container');
    const marqueeContent = document.getElementById('marquee-content');

    if (marqueeContainer && marqueeContent) {
      const marqueeWidth = marqueeContent.clientWidth;
      const containerWidth = marqueeContainer.clientWidth;
      const animationDuration = (marqueeWidth / containerWidth) * 10; // Adjust speed as needed

      marqueeContent.style.animation = `marquee ${animationDuration}s linear infinite`;
    }
  }, [lbName, lbNoticeboardUrl, message]);

  const handleLogout = () => {
    document.cookie = 'authToken=; path=/; domain=busparrot.com'; // Make sure domain matches
    console.log('User has logged out', document.cookie);
    window.location.reload();
  };

  return (
    <div style={{ textAlign: 'center', marginBottom: '750px' }}>
      <style>
        {`
          @keyframes marquee {
            from {
              transform: translateX(100%);
            }
            to {
              transform: translateX(-100%);
            }
          }

          #marquee-container {
            overflow: hidden;
            white-space: nowrap;
            box-sizing: border-box;
            display: flex;
            align-items: center;
          }

          #marquee-content {
            display: inline-block;
            white-space: nowrap;
            animation: marquee linear infinite;
          }

          .noticeboard {
            margin-left: 20px;
          }

          .noticeboard a {
            color: #007bff;
            text-decoration: none;
            font-size: 14px;
          }

          .noticeboard a:hover {
            text-decoration: underline;
          }

          .footer {
            position: fixed;
            bottom: 0;
            width: 100%;
            background-color: #e3f2da;
            text-align: center;
            padding: 5px 0;
            box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.1);
          }

          .image2 {
            margin-left: 0px;
            margin-right: 10px;
            height: 10px;
            width: 10px;
          }

          .navbar-custom {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
          }

          .navbar-brand-custom {
            display: flex;
            align-items: center;
          }

          .navbar-nav-custom {
            display: flex;
            align-items: center;
          }

          .navbar-nav-custom .linkadd1 {
            font-size: 14px;
            color: black;
            margin-left: 10px;
          }

          .navbar-nav-custom .linkadd1:first-child {
            margin-left: 0;
          }

          .bus {
            font-family: 'Comfortaa', cursive;
            font-weight: bolder;
          }
        `}
      </style>

      <div className="footer">
        <div id="marquee-container">
          <div id="marquee-content">
            {lbName && <span>{lbName} Welcomes You</span>}
            {message && <span> , {message}</span>}
            {lbNoticeboardUrl && (
              <div className="noticeboard">
                <span>Noticeboard: </span>
                <a href={lbNoticeboardUrl}>{lbNoticeboardUrl}</a>
              </div>
            )}
          </div>
        </div>
        <Navbar className="navbar-custom">
          <Container className="d-flex align-items-center justify-content-between">
            <div className="navbar-brand-custom">
              <Navbar.Brand>
                <img className="image2" src={busparroticon} alt="BusParrot" />
              </Navbar.Brand>
              <Nav className="navbar-nav-custom">
                <Link className="linkadd1" to="/"><u>HOME</u></Link>
                <Link className="linkadd1" to="/about">ABOUT</Link>
                <Link className="linkadd1" to="/channelpartner"><u>PARTNER WITH US</u></Link>
                {cookieNames[0] !== undefined ? (
                  <Link className="linkadd1" onClick={handleLogout}>LOGOUT</Link>
                ) : (
                  <Link className="linkadd1" to="/login">LOGIN</Link>
                )}
              </Nav>
            </div>
          </Container>
        </Navbar>
      </div>
    </div>
  );
};

export default Footer;
