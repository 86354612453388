import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import Details from './Details';
import axios from 'axios';
import ReactHtmlParser from 'react-html-parser'; // Import react-html-parser
import '../Styles/details.css';
import eyes from '../Images/eyes.png';
import { useNavigate } from 'react-router-dom';

const Traindetails = () => {
  const location = useLocation();
  const { train } = location.state;

  const tableRef = useRef(null); // Create a ref for the table element
  const nav = useNavigate();

  const [tableData, setTableData] = useState('');

  useEffect(() => {
    const apiUrl = `https://api.busparrot.com/getlivetrainstation.php?stname=${train.label}&stid=${train.value}`;

    const fetchData = async () => {
      try {
        const response = await axios.get(apiUrl);
        const htmlTable = response.data.data; // Extract the HTML table from the response
        setTableData(htmlTable);
      } catch (error) {
        console.error('Error fetching train details:', error);
      }
    };

    fetchData();
  }, [train.value, train.id]);

  return (
    <div className="bg-body-tertiary mb-3">
      <Details />
      <div className="t1">
        {ReactHtmlParser(tableData)}
      </div>
    </div>
  );
};

export default Traindetails;
