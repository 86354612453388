import React, { useState ,useEffect} from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import Navpage6 from './Navpage6';
import busparroticon from '../Images/busparrotlogo.png';
import '../Styles/buses.css'; // Import your custom CSS file
import facebook from '../Images/facebook.png'
import linkedin from '../Images/linkedin.png'
import twitter from '../Images/twitter.png'
import youtube from '../Images/youtube.png'
import axios from 'axios';
import Select from 'react-select';
import { css } from '@emotion/react';
import Map from './Map';
import like from '../Images/like.png';
import dislike from '../Images/dislike.png';
const Bussearch = () => {
  const [typedInput, setTypedInput] = useState('');
  const [activeButton, setActiveButton] = useState(null);
  const navigate = useNavigate(); // Initialize useNavigate
  const nav =useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [selectedStop, setSelectedStop] = useState('');
  const [stopOptions, setStopOptions] = useState([]);
  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: '300px', // Set the width to your desired value
    }),
    option: (provided, state) => ({
      ...provided,
      color: 'black', // Set the text color to black
      width: '300px',
    }),
  };
  const handleClick = (buttonName) => {
    setActiveButton(buttonName);

    // Use navigate to go to the corresponding route
    switch (buttonName) {
      case 'button1':
        navigate('/');
        break;
      case 'button2':
        navigate('/trip');
        break;
      case 'button3':
        navigate('/route');
        break;
      case 'button4':
        navigate('/bus');
        break;
      default:
        break;
    }
  };
  
  const handleSelectChange = (selectedOption) => {
    setSelectedStop(selectedOption);
  };
  const fetchStops = (inputValue) => {
    // Replace with your API URL
    const apiUrl = `https://api.busparrot.com/searchbus.php?term=${inputValue}`;

    // Fetch data from the API
    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        const options = data.map((stop) => ({
          value: stop.id,
          label: stop.value,
        }));
        setStopOptions(options);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  };

 
  const handleSearch = () => {
    // Replace with your search logic using the selected stop (selectedStop.value)
    if (selectedStop) {
      console.log('Searching for stop:', selectedStop);
      nav('/busdetails', { state: { bus: selectedStop } })
    } else {
      console.log('Please select a stop to search.');
    }
  };



  const handlemessage = async (trip) => {
    const ipApiResponse = await axios.get('https://api.ipify.org?format=json');
    const userIpAddress = ipApiResponse.data.ip;
    const currentTime = new Date(); 
    const feedbackData = {
      appname: 'Bus',
      category: 'Bus',
      infoid: "tripid"+trip.id,
      customerid: 'Not defined',
      ipaddress: userIpAddress,
      feedback: 'Correct',
      message: 'Not defined',
      time: currentTime.toLocaleString(),
    }

    const apiUrl = 'https://yatraparrot.com/feedback/customer_feedback.php';
    const urlWithParams = `${apiUrl}?appname=${feedbackData.appname}&category=${feedbackData.category}&infoid=${feedbackData.infoid}&customerid=${feedbackData.customerid}&ipaddress=${feedbackData.ipaddress}&feedback=${feedbackData.feedback}&message=${feedbackData.message}&time=${feedbackData.time}`;
    
  
    try {
      const response = await fetch( urlWithParams, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // Add any other headers as needed
        },
        body: JSON.stringify({
          // Add your data here
          
          // Add other data fields as needed
        }),
      });
  
      console.log(response, "feedback response");


      if (response.status === 200) {
        // Display an alert when feedback is successfully posted
        window.alert('Feedback posted successfully!');
      } else {
        // Display an alert for any other response status
        window.alert('Failed to post feedback. Please try again.');
      }
      // Handle the response accordingly
    } catch (error) {
      console.error('Error sending feedback:', error);
    }
  };
  const smallButtonStyle = {
    width: '40px', 
  };



  const handlemessage1 = async (trip) => {
    const ipApiResponse = await axios.get('https://api.ipify.org?format=json');
    const userIpAddress = ipApiResponse.data.ip;
    const currentTime = new Date();
  
    // Show a prompt to enter the message
    const userMessage = window.prompt('Enter your feedback message:');
  
    // Check if the user clicked cancel
    if (userMessage === null) {
      window.alert('Feedback submission canceled.');
      return;
    }
  
    const feedbackData = {
      appname: 'Bus',
      category: 'Bus',
      infoid: 'tripid' + trip.id,
      customerid: 'Not defined',
      ipaddress: userIpAddress,
      feedback: 'InCorrect',
      message: userMessage || 'No Messages', // Use user input or 'No Messages' if empty
      time: currentTime.toLocaleString(),
    };
  
    const apiUrl = 'https://yatraparrot.com/feedback/customer_feedback.php';
    const urlWithParams = `${apiUrl}?appname=${feedbackData.appname}&category=${feedbackData.category}&infoid=${feedbackData.infoid}&customerid=${feedbackData.customerid}&ipaddress=${feedbackData.ipaddress}&feedback=${feedbackData.feedback}&message=${feedbackData.message}&time=${feedbackData.time}`;
  
    try {
      const response = await fetch(urlWithParams, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // Add any other headers as needed
        },
        body: JSON.stringify({
          // Add your data here
          // Add other data fields as needed
        }),
      });
  
      console.log(response, 'feedback response');
  
      if (response.status === 200) {
        // Display an alert when feedback is successfully posted
        window.alert('Feedback posted successfully!');
      } else {
        // Display an alert for any other response status
        window.alert('Failed to post feedback. Please try again.');
      }
      // Handle the response accordingly
    } catch (error) {
      console.error('Error sending feedback:', error);
    }
  };
  
  const smallButtonStyle1 = {
    width: '30px', // Adjust the width value as needed
   
    
    // backgroundColor: ' #f72e42',
    marginLeft: '10px',
    marginTop:'8px'
  };
  
  return (
    <div>
      <Navpage6 />
      <div className='subbody container-fluid'>
      <br/>
     
      <div className="button-container">
        <button
            className={`button ${activeButton === 'button1' ? 'active' : ''}`}
            onClick={() => handleClick('button1')}
           
          >
            Stop
          </button>
          <button
            className={`button ${activeButton === 'button2' ? 'active' : ''}`}
            onClick={() => handleClick('button2')}
           
          >
            Trip
          </button>
          <button
            className={`button ${activeButton === 'button3' ? 'active' : ''}`}
            onClick={() => handleClick('button3')}
          >
            Route
          </button>
          
          <button
            className={`button ${activeButton === 'button4' ? 'active' : ''}`}
            onClick={() => handleClick('button4')}
            style={{ backgroundColor: '#76c045' }}
          >
            Bus
          </button>
        </div>




      <div className="trip-container">
          <h2>Search Bus</h2>
          <div className="input-container">
            {/* Searchable select box */}
           <Select

  value={selectedStop}
  onInputChange={(inputValue) => {
    setTypedInput(inputValue);
    fetchStops(inputValue);
  }}
  onChange={handleSelectChange}
  options={stopOptions}
  placeholder="Select Bus"
  defaultValue={null}
  styles={customStyles}
/>
          </div>

          <div className="input-container">
            <button className="search-button" onClick={handleSearch}>
              Search
            </button>
          </div>

      

          {/* Display search results */}
          <div className="search-results">
            {searchResults.map((result) => (
              <div key={result.id} className="search-result">
                <p>Bus Name: {result.name}</p>
                <p>Bus Location: {result.location}</p>
                {/* Display other properties from the search result as needed
                <button style={smallButtonStyle} onClick={() => handlemessage(result)}>Correct</button>
        <button style={smallButtonStyle1} onClick={() => handlemessage1(result)}>InCorrect</button>
              </div> */}
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div onClick={() => handlemessage(result)}>
                  <img src={like} alt="Correct" style={smallButtonStyle} />
                </div>
                <div onClick={() => handlemessage1(result)}>
                  <img src={dislike} alt="InCorrect" style={smallButtonStyle1} />
                </div>
              </div><br/>
            </div>
            ))}
          </div>
        </div>
        
      </div>
      <div className='mapstyle'><Map/></div>
    <br/>
      <div style={{textAlign:'center',marginBottom:'750px'}}>
    <a href='https://m.facebook.com/100063656121677/'><img className="imagesocial" style={{width:'30px'}} src={facebook} alt=""></img></a>
<a href='https://twitter.com/BusParrot?s=08'><img className="imagesocial" style={{width:'30px'}} src={twitter} alt=""></img></a>
<a href='https://youtube.com/@user-mk1uk6sq2t'><img className="imagesocial" style={{width:'30px'}} src={youtube} alt=""></img></a>
<a href='https://www.linkedin.com/company/busparrot/'><img className="imagesocial" style={{width:'30px'}} src={linkedin} alt=""></img></a>
</div>
    </div>
  );
}

export default Bussearch;
