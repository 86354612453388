import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Details from './Details';
import axios from 'axios';
import '../Styles/details.css';
import eyes from '../Images/eyes.png';
import like from '../Images/like.png';
import whatsapp from '../Images/whatsapp1.png';
import navigate from '../Images/navigate.png';
import call from '../Images/call.png';
import website from '../Images/call.png'; 
import dislike from '../Images/dislike.png';
import greenbus from '../Images/greenbus.png'
import { useNavigate } from 'react-router-dom'; 
import Navpage6 from './Navpage6';
import Footer from './Footer';

const Stopscandetails = () => {
  let busIconDisplayed = false;
//   const location = useLocation();
//   const { from } = location.state;
const [productdetail,setproductdetail]=useState([]);
const [nearestStops, setNearestStops] = useState([]);
  const [tripDetails, setTripDetails] = useState([]);
  const [stopName,setStopName]=useState([]);
  const [latitude,setLatitude]=useState([]);
  const [longitude,setLongitude]=useState([]);
  const [advId, setAdvId] = useState(null);
  const [defaultSelectValue, setDefaultSelectValue] = useState(null);
  const [fetchedClientInfo, setFetchedClientInfo] = useState(null);
  const [currentTimeInMinutes, setCurrentTimeInMinutes] = useState(null);
  const [imageLoadError, setImageLoadError] = useState(false);
const nav = useNavigate();
const location = useLocation();
  const divStyle = {
    textAlign: 'center',

    fontFamily: 'Comfortaa, sans-serif', // Specify Comfortaa as the font
  };
  const urlParams = new URLSearchParams(window.location.hash.split('?')[1]);
    const qrslnoFromUrl1 = urlParams.get('id');
    // const logCurrentTime = () => {
    //   const now = new Date();
    //   const currentHours = now.getHours();
    //   const currentMinutes = now.getMinutes();
    //   const totalMinutes = currentHours * 60 + currentMinutes;
  
    //   console.log('Current Time in Minutes:', totalMinutes);
    //   setCurrentTimeInMinutes(totalMinutes);
    // };
  
    // useEffect(() => {
    //   logCurrentTime(); // Initial call to log the current time
  
    //   const intervalId = setInterval(logCurrentTime, 60000); // Log the time every 1 minute
  
    //   return () => clearInterval(intervalId); // Clean up the interval on component unmount
    // }, []);
    useEffect(() => {
      console.log(qrslnoFromUrl1, 'qrslnoFromUrl1');
  
      const fetchData = async (currentTimeInMinutes) => {
          //fetch stopdetails and advertisement details
          try {
              const response = await axios.get('https://busparrot.com/homephp/checkqrdb.php', {
                  params: {
                      id: qrslnoFromUrl1, // Pass the id as a parameter to the PHP file
                  },
              });
              console.log('Response from PHP file:', response.data);
              const data = response.data;
  
              // Find matching entry in the data array based on qrslno
              const matchingEntry1 = data.find(entry => entry.qrslno === qrslnoFromUrl1);
              console.log('Matching Entry 1:', matchingEntry1);
  
              if (!matchingEntry1) {
                  console.log('No matching entry found for QRSLNO:', qrslnoFromUrl1);
              } else {
                  console.log('Matching entry found:', matchingEntry1);
              }
  
              // Loop through each entry in the data array and log QRSLNO for each entry
              data.forEach(entry => {
                  console.log('QRSLNO in data:', entry.qrslno);
                  console.log('Data type of qrslno:', typeof entry.qrslno);
              });
  
              const matchingEntry = data.find(entry => (entry => entry.qrslno.toString() === qrslnoFromUrl1) || (entry.stop_id === qrslnoFromUrl1 && entry.qrslno !== qrslnoFromUrl1) || (entry.stopid === qrslnoFromUrl1));
              console.log('Matching Entry above:', matchingEntry);
  
              if (matchingEntry) {
                  console.log('Matching Entry:', matchingEntry);
  
                  let apiUrl;
                  if (matchingEntry.stopid) {
                      apiUrl = `https://busparrot.com/pis/api/v1/getstoptrips.php?stopid=${matchingEntry.stopid}&curtimemin=${currentTimeInMinutes}`;
                  } else if (matchingEntry.stop_id) {
                      apiUrl = `https://busparrot.com/pis/api/v1/getstoptrips.php?stopid=${matchingEntry.stop_id}&curtimemin=${currentTimeInMinutes}`;
                  } else {
                      console.log('Neither stopid nor stop_id found in matching entry.');
                      return;
                  }
  
                  const response1 = await axios.get(apiUrl);
  
                  setTripDetails(response1.data.trips);
                  setStopName(matchingEntry.stop_name);
                  setLatitude(matchingEntry.stop_lat);
                  setLongitude(matchingEntry.stop_lon);
                  document.cookie = `latitude=${matchingEntry.stop_lat}`;
                  document.cookie = `longitude=${matchingEntry.stop_lon}`;
                  
                  const advId = matchingEntry.adv_id;
                  setAdvId(advId);
                  setFetchedClientInfo(matchingEntry);
  
                  console.log('Fetched Details:', response1.data);
                  console.log('Fetched adv_id:', advId);
                  console.log(tripDetails, 'hhh');
                  const category = 'scan';
                  sendQrUsageData(matchingEntry, advId,category);
                  await fetchNearestStops(matchingEntry.stop_lat, matchingEntry.stop_lon);
              } else {
                const category = 'scan';
                  console.log('No matching entry found.');
                  sendQrUsageData(qrslnoFromUrl1, advId,category);
              }
          } catch (error) {
              console.error('Error fetching trip details:', error);
          }
      };
     
  
      const logCurrentTime = () => {
          const now = new Date();
          const currentHours = now.getHours();
          const currentMinutes = now.getMinutes();
          const totalMinutes = currentHours * 60 + currentMinutes;
  
          console.log('Current Time in Minutes:', totalMinutes);
          setCurrentTimeInMinutes(totalMinutes); // Update currentTimeInMinutes
          fetchData(totalMinutes); // Call fetchData with the updated currentTimeInMinutes
      };
  
      // Initial call to logCurrentTime
      logCurrentTime();
  
  }, []);
  
  useEffect(() => {
    if (!advId && nearestStops.length > 0) {
      const lbcode = nearestStops[0].lbcode;
      
      fetch('https://busparrot.com/pis/nbqr/getproducts.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({ lbcode })
      })
      .then(response => {
        // Check if the response is ok (status code in the range 200-299)
        if (!response.ok) {
            throw new Error('Network response was not ok ' + response.statusText);
        }
        return response.json(); // Parse the response as JSON
    })
      .then(data => {
        const productData = data.data;

        // Format the image URL directly without a separate function
        if (productData.image) {
            productData.image = productData.image.replace(/\\/g, '').replace(/^.*?assets/, 'https://busparrot.com/shoppingcart/assets');
        }
        setproductdetail(productData);
        console.log('Server response:', data);
      })
      .catch(error => console.error('Error:', error));
    }
  }, [advId, nearestStops]);
  
  const fetchNearestStops = async (latitude, longitude) => {
    try {
      console.log(latitude,longitude,"latlon");
      const apiUrl = `https://api.busparrot.com/getneareststops.php?lat=${latitude}&lon=${longitude}&radius=2`;
      console.log(apiUrl,'stops')
      const response = await axios.get(apiUrl);
  
      console.log('API Response:', response);
  
      const nearestStopsData = response.data.stops.map((stop) => ({
        ID: stop.stop_id,
        stop_name: stop.stop_name,
        distance: stop.distance,
        lat: stop.stop_lat,
        lon: stop.stop_lon,
        lbcode:stop.stop_localbodycode,
        page:'stop',
      }));
  
      setNearestStops(nearestStopsData);
      setDefaultSelectValue({
        value: nearestStopsData[0].ID,
        label: nearestStopsData[0].stop_name,
      });
    } catch (error) {
      console.error('Error fetching nearest stops data:', error);
    }
  };
  

  



  const sendQrUsageData = async (matchingEntry, advId,category) => {
    try {
        // Fetch current IP address
        const ipApiResponse = await axios.get('https://api.ipify.org?format=json');
        const userIpAddress = ipApiResponse.data.ip;

        // Fetch current timestamp
        const timestampNow = new Date().toISOString().slice(0, 19).replace('T', ' ');
        const qrcode = qrslnoFromUrl1 ? qrslnoFromUrl1 : matchingEntry.stopid;

        // Send data to the server-side script
        try {
          
          const response = await axios.post(
            `https://busparrot.com/home/db/qrusage.php?` +
            `userip=${encodeURIComponent(userIpAddress)}&` +
            `timestampnow=${encodeURIComponent(timestampNow)}&` +
            `qrtype=${encodeURIComponent("STOP")}&` +
            `qrcode=${encodeURIComponent(qrcode)}&` +
            `adv_id=${encodeURIComponent(advId)}&` +
            `client_web=${encodeURIComponent(matchingEntry.client_web)}&` +
            `client_phone=${encodeURIComponent(matchingEntry.client_phone)}&` +
            `client_latitude=${encodeURIComponent(matchingEntry.client_latitude)}&` +
            `client_longitude=${encodeURIComponent(matchingEntry.client_longitude)}&` +
            `client_whatsapp=${encodeURIComponent(matchingEntry.client_watsapp)}&` +
            `stop_id=${encodeURIComponent(matchingEntry.stop_id)}&` +
            `category=${encodeURIComponent(category)}`
          );
          
            console.log('Response from server:', response.data);
        } catch (error) {
            console.error('Error sending QR usage data:', error);
        }
    } catch (error) {
        console.error('Error fetching IP address:', error);
    }
};

  
  
  

  const handleclick = (trip) => {

    nav('/stopbusdetails', { state: { route: trip.Headsign, bus: trip.Bus ,id:trip.TripID} })
  }

  const handlemessage = async (trip) => {
    const ipApiResponse = await axios.get('https://api.ipify.org?format=json');
    const userIpAddress = ipApiResponse.data.ip;
    const currentTime = new Date(); 
    const feedbackData = {
      appname: 'Bus',
      category: 'Stop',
      infoid: "tripid : "+trip.TripID,
      customerid: 'Not defined',
      ipaddress: userIpAddress,
      feedback: 'Correct',
      message: 'Not defined',
      time: currentTime.toLocaleString(),
    }

    const apiUrl = 'https://busparrot.com/feedback/customer_feedback.php';
    const urlWithParams = `${apiUrl}?appname=${feedbackData.appname}&category=${feedbackData.category}&infoid=${feedbackData.infoid}&customerid=${feedbackData.customerid}&ipaddress=${feedbackData.ipaddress}&feedback=${feedbackData.feedback}&message=${feedbackData.message}&time=${feedbackData.time}`;
    
  
    try {
      const response = await fetch( urlWithParams, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // Add any other headers as needed
        },
        body: JSON.stringify({
          // Add your data here
          
          // Add other data fields as needed
        }),
      });
  
      console.log(response, "feedback response");


      if (response.status === 200) {
        // Display an alert when feedback is successfully posted
        window.alert('Feedback posted successfully!');
      } else {
        // Display an alert for any other response status
        window.alert('Failed to post feedback. Please try again.');
      }
      // Handle the response accordingly
    } catch (error) {
      console.error('Error sending feedback:', error);
    }
  };
  const smallButtonStyle = {
    width: '40px', // Adjust the width value as needed
    
  };

  const divsty = {
textAlign:'center'
    
  };


  const handlemessage1 = async (trip) => {
    const ipApiResponse = await axios.get('https://api.ipify.org?format=json');
    const userIpAddress = ipApiResponse.data.ip;
    const currentTime = new Date();
  
    // Show a prompt to enter the message
    const userMessage = window.prompt('Enter your feedback message:');
  
    // Check if the user clicked cancel
    if (userMessage === null) {
      window.alert('Feedback submission canceled.');
      return;
    }
  
    const feedbackData = {
      appname: 'Bus',
      category: 'Stop',
      infoid: 'tripid' + trip.TripID,
      customerid: 'Not defined',
      ipaddress: userIpAddress,
      feedback: 'InCorrect',
      message: userMessage || 'No Messages', // Use user input or 'No Messages' if empty
      time: currentTime.toLocaleString(),
    };
  
    const apiUrl = 'https://busparrot.com/feedback/customer_feedback.php';
    const urlWithParams = `${apiUrl}?appname=${feedbackData.appname}&category=${feedbackData.category}&infoid=${feedbackData.infoid}&customerid=${feedbackData.customerid}&ipaddress=${feedbackData.ipaddress}&feedback=${feedbackData.feedback}&message=${feedbackData.message}&time=${feedbackData.time}`;
  
    try {
      const response = await fetch(urlWithParams, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // Add any other headers as needed
        },
        body: JSON.stringify({
          // Add your data here
          // Add other data fields as needed
        }),
      });
  
      console.log(response, 'feedback response');
  
      if (response.status === 200) {
        // Display an alert when feedback is successfully posted
        window.alert('Feedback posted successfully!');
      } else {
        // Display an alert for any other response status
        window.alert('Failed to post feedback. Please try again.');
      }
      // Handle the response accordingly
    } catch (error) {
      console.error('Error sending feedback:', error);
    }
  };
  
  const smallButtonStyle1 = {
    width: '30px', // Adjust the width value as needed
   
    
    // backgroundColor: ' #f72e42',
    marginLeft: '10px',
    marginTop:'8px'
  };
  
  return (
    <div>
      <Navpage6/>
    <div className='bg-body-tertiary mb-3'>
     
   
      <div style={divStyle} className="bg1">
        <h6 className='hstyle'>
        <b>  You are at {stopName}</b>
        </h6>
       
      </div>
      
    

      {advId && fetchedClientInfo ? (
  <div style={{ textAlign: 'center', marginTop: '20px' }}>
    {(() => {
      const category = 'click';
      return (
        `https://yatraparrot.com/adplatform/upload/${advId}.gif` && (
          <a
            href={`https://yatraparrot.com/adplatform/upload/${advId}/`}
            target="_blank"
            onClick={() => sendQrUsageData(fetchedClientInfo, advId, category)}
          >
            <img
              src={`https://yatraparrot.com/adplatform/upload/${advId}.gif`}
              alt=""
              style={{ width: '400px', height: '300px', display: imageLoadError ? 'none' : 'block' }}
              onError={() => setImageLoadError(true)}
            />
           

          </a>
        )
      );
    })()}
    {!imageLoadError && (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px' }}>
        {(() => {
          const category = 'website';
          return (
            <a 
              href={`https://${fetchedClientInfo.client_web}`} 
              target="_blank" 
              rel="noopener noreferrer" 
              onClick={() => sendQrUsageData(fetchedClientInfo, advId, category)}
            >
              <img src={website} alt="Website" style={{ width: '60px', marginLeft: '10px' }} />
            </a>
          );
        })()}
        <span style={{ margin: '0 10px' }}><b>{fetchedClientInfo.client_phone}</b></span>
        {(() => {
          const category = 'call';
          return (
            <a 
              href={`tel:${fetchedClientInfo.client_phone}`} 
              onClick={() => sendQrUsageData(fetchedClientInfo, advId, category)}
            >
              <img src={call} alt="Call" style={{ width: '60px', marginRight: '10px' }} />
            </a>
          );
        })()}
        <span><b>{fetchedClientInfo.client_phone}</b></span>
        {(() => {
          const category = 'navigate';
          return (
            <a 
              href={`https://www.google.com/maps/dir/?api=1&destination=${fetchedClientInfo.client_latitude},${fetchedClientInfo.client_longitude}`} 
              target="_blank" 
              rel="noopener noreferrer" 
              onClick={() => sendQrUsageData(fetchedClientInfo, advId, category)}
            >
              <img src={navigate} alt="Navigate" style={{ width: '60px', margin: '0 10px' }} />
            </a>
          );
        })()}
        <span><b>Directions</b></span>
        {(() => {
          const category = 'whatsapp';
          return (
            <a 
              href={`https://wa.me/${fetchedClientInfo.client_watsapp}`} 
              target="_blank" 
              rel="noopener noreferrer" 
              onClick={() => sendQrUsageData(fetchedClientInfo, advId, category)}
            >
              <img src={whatsapp} alt="WhatsApp" style={{ width: '60px', marginLeft: '10px' }} />
            </a>
          );
        })()}
      </div>
    )}
  </div>
) : (
<div style={{ textAlign: 'center', marginTop: '20px' }}>
  {nearestStops.length > 0 && (
    <>
      {/* Log product detail image to console */}
      {console.log('Product Detail:', productdetail.image)}

      {/* Show the fallback image from product detail if it exists */}
      {productdetail.image && (
 <a 
 href={`https://busparrot.com/ShoppingCart/?productId=${productdetail.id}&shopId=${productdetail.shopid}&image=${productdetail.image}`} 
 target="_blank" 
 rel="noopener noreferrer"
>
 <img
   src={productdetail.image}
   alt={productdetail.name || "Product Image"}
   style={{ width: '400px', height: '400px', display: 'block' }}
 />
</a>

)}
      
      {/* Display product description below the image */}
      {productdetail.description && (
        <p><b>{productdetail.description}</b></p>
      )}
    </>
  )}
</div>



)}

<br/>



  {/* {tripDetails && tripDetails.success === 1 && (
    <div className="trip-list">
      {tripDetails.result.map((trips) => (
        <div className='det' key={trips.TripID}>
          <img className='image' src={eyes} alt="BusParrot" />
          <div className="trip-details">
            <p> <b>{trips.Arrival} Arrival</b></p>
            <h6><b> {trips.Headsign}</b></h6>
            <span className='sp' onClick={() => handleclick(trips)}> <b>{trips.Bus}</b></span>
            <p><b>{trips.departure} Departure</b></p>
          </div>
        </div>
      ))}
    </div>
  )} */}
  


{tripDetails.map((trips) => ( 
  
  <div className="trip-details" key={trips.TripID} style={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
   
    {trips.istodaytrip === "1" && !busIconDisplayed ? (
      // Render bus icon only if istodaytrip is "1" and busIconDisplayed is false
      <div style={{ 
        position: 'relative',
        borderLeft: trips.istodaytrip === "0" ? '20px solid green' : '20px solid red',
        height: '100%',
        marginRight: '10px',
        paddingLeft: '20px', // Space for icon
        marginLeft: trips.istodaytrip === "0" || trips.istodaytrip === "1" ? '30px' : '0', // Adjust the left margin as needed

      }}>
        <img src={greenbus} alt="Green Bus" style={{ width: '40px', height: '40px' }} />
      </div>
    ) : (
      // Render red dot or green border for other istodaytrip values
      <div style={{ 
        position: 'relative',
        borderLeft: trips.istodaytrip === "0" ? '20px solid green' : '20px solid red',
        height: '100%',
        marginRight: '10px',
        paddingLeft: '20px', // Space for icon
        marginLeft: trips.istodaytrip === "0" || trips.istodaytrip === "1" ? '30px' : '0', // Adjust the left margin as needed
      }}>
        <div style={{ 
          position: 'absolute',
          top: '50%',
          transform: 'translateY(-50%)',
          left: '0',
          width: '20px', // Adjust size as needed
          height: '20px',
        }}>
          {trips.istodaytrip === "0" ? (
            <div style={{ 
              width: '10px',
              height: '10px',
              borderRadius: '50%',
              backgroundColor: 'green',
            }}></div>
          ) : (
            <div style={{ 
              width: '10px',
              height: '10px',
              borderRadius: '50%',
              backgroundColor: 'red',
            }}></div>
          )}
        </div>
      </div>
    )}

    <div style={{ 
      flex: 1,
      paddingLeft: '10px', // Adjust spacing as needed
    }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div onClick={() => handlemessage(trips)}>
          <h7>Was this Correct?</h7>
          <img src={like} alt="Correct" style={smallButtonStyle} />
          <img src={dislike} alt="InCorrect" style={smallButtonStyle1} />
        </div>
      </div>
      
      <p><b>{trips.Arrival} Arrival</b></p>
      <h6><b>{trips.Headsign}</b></h6>
      <span className='sp' onClick={() => handleclick(trips)}><b>{trips.Bus}</b></span>
      <p><b>{trips.Departure} Departure</b></p>
    </div>
    
    {/* Update busIconDisplayed flag if istodaytrip is "1" */}
    {trips.istodaytrip === "1" && !busIconDisplayed && (busIconDisplayed = true)}
  </div>
))}



    {/* </div> */}

    {/* Render tomorrow's trips */}



</div>
<div>
  {nearestStops.length > 0 && (
    <div >
      <Footer nearestStops={nearestStops} />
    </div>
  )}
</div>

</div>
  );
  
 
};


export default Stopscandetails;