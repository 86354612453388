import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Details from './Details';
import axios from 'axios';
import '../Styles/details.css';
import eyes from '../Images/eyes.jpeg';
import { useNavigate } from 'react-router-dom';
import like from '../Images/like.png';
import dislike from '../Images/dislike.png';

const Busdetails = () => {
  const location = useLocation();
  const { bus } = location.state;
  const [tripDetails, setTripDetails] = useState([]);

  const divStyle = {
    textAlign: 'center',
    fontFamily: 'Comfortaa, sans-serif', // Specify Comfortaa as the font
  };

  const nav = useNavigate();

  useEffect(() => {
    // Define the URL with from.value and to.value
    const apiUrl = `https://api.busparrot.com/getbustrips.php?regno=${bus.value}`;

    const fetchData = async () => {
      try {
        // Fetch trip details using Axios and await the response
        const response = await axios.get(apiUrl);
        console.log(response);
        // Set the trip details in the state
        setTripDetails(response.data.result);
      } catch (error) {
        console.error('Error fetching trip details:', error);
      }
    };

    fetchData();
  }, [bus.value]);

  const handlemessage = async (trip) => {
    const ipApiResponse = await axios.get('https://api.ipify.org?format=json');
    const userIpAddress = ipApiResponse.data.ip;
    const currentTime = new Date();
    const feedbackData = {
      appname: 'Bus',
      category: 'Bus',
      infoid: 'tripid : ' + trip.id,
      customerid: 'Not defined',
      ipaddress: userIpAddress,
      feedback: 'Correct',
      message: 'Not defined',
      time: currentTime.toLocaleString(),
    };

    const apiUrl = 'https://yatraparrot.com/feedback/customer_feedback.php';
    const urlWithParams = `${apiUrl}?appname=${feedbackData.appname}&category=${feedbackData.category}&infoid=${feedbackData.infoid}&customerid=${feedbackData.customerid}&ipaddress=${feedbackData.ipaddress}&feedback=${feedbackData.feedback}&message=${feedbackData.message}&time=${feedbackData.time}`;

    try {
      const response = await fetch(urlWithParams, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({}),
      });

      console.log(response, 'feedback response');

      if (response.status === 200) {
        window.alert('Feedback posted successfully!');
      } else {
        window.alert('Failed to post feedback. Please try again.');
      }
    } catch (error) {
      console.error('Error sending feedback:', error);
    }
  };

  const smallButtonStyle = {
    width: '40px',
  };

  const handlemessage1 = async (trip) => {
    const ipApiResponse = await axios.get('https://api.ipify.org?format=json');
    const userIpAddress = ipApiResponse.data.ip;
    const currentTime = new Date();

    const userMessage = window.prompt('Enter your feedback message:');

    if (userMessage === null) {
      window.alert('Feedback submission canceled.');
      return;
    }

    const feedbackData = {
      appname: 'Bus',
      category: 'Bus',
      infoid: 'tripid' + trip.id,
      customerid: 'Not defined',
      ipaddress: userIpAddress,
      feedback: 'InCorrect',
      message: userMessage || 'No Messages',
      time: currentTime.toLocaleString(),
    };

    const apiUrl = 'https://yatraparrot.com/feedback/customer_feedback.php';
    const urlWithParams = `${apiUrl}?appname=${feedbackData.appname}&category=${feedbackData.category}&infoid=${feedbackData.infoid}&customerid=${feedbackData.customerid}&ipaddress=${feedbackData.ipaddress}&feedback=${feedbackData.feedback}&message=${feedbackData.message}&time=${feedbackData.time}`;

    try {
      const response = await fetch(urlWithParams, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({}),
      });

      console.log(response, 'feedback response');

      if (response.status === 200) {
        window.alert('Feedback posted successfully!');
      } else {
        window.alert('Failed to post feedback. Please try again.');
      }
    } catch (error) {
      console.error('Error sending feedback:', error);
    }
  };

  const smallButtonStyle1 = {
    width: '30px',
    marginLeft: '10px',
    marginTop: '8px',
  };

  const arrivalStyle = {
    color: 'green',
    fontWeight: 'bold',
    fontSize: '3em',
    // backgroundColor: '#ebd69d', // Highlight background
    padding: '5px',
    borderRadius: '5px',
  };

  const departureStyle = {
    color: 'red',
    fontWeight: 'bold',
    fontSize: '3em',
    // backgroundColor: '#ebd69d', // Highlight background
    padding: '5px',
    borderRadius: '5px',
  };

  
  // Media queries for responsiveness
  const responsiveStyle = {
    '@media (max-width: 600px)': {
      arrivalStyle: {
        fontSize: '1em',
      },
      departureStyle: {
        fontSize: '1em',
      },
      smallButtonStyle: {
        width: '30px',
      },
      smallButtonStyle1: {
        width: '30px',
      },
    },
  };

  return (
    <div className='bg-body-tertiary mb-3'>
      <Details />
      <div style={divStyle} className='bg1'>
        <h6 className='hstyle'>
          <b>{bus.label}</b>
        </h6>
      </div>
      {tripDetails && Array.isArray(tripDetails) && tripDetails.length > 0 ? (
        <div className='trip-list'>
          {tripDetails.map((trips) => (
            <div className='det' key={trips.TripID}>
              <div className='trip-details'>
                <br />
                <img className='image' src={eyes} alt='BusParrot' />
                <p style={{ ...arrivalStyle, ...responsiveStyle['@media (max-width: 600px)'].arrivalStyle }}>
  <span style={{ fontSize: '2em', fontWeight: 'bold', color: 'green' }}>{trips.Arrival}</span> <b>Arrival</b>
</p>

                <h6><b>{trips.Headsign}</b></h6>
               <h6><b>{bus.label}</b></h6>
                <p style={{ ...departureStyle, ...responsiveStyle['@media (max-width: 600px)'].departureStyle }}>
                <span style={{ fontSize: '2em', fontWeight: 'bold', color: 'red' }}>{trips.Departure} </span> <b>Departure</b></p>
                <div className='container-style'>
              <h7><b>Was this Correct?</b></h7>
              <div onClick={() => handlemessage(trips)} style={{ display: 'inline-block' }}>
                <img src={like} alt='Correct' style={{ ...smallButtonStyle, ...responsiveStyle['@media (max-width: 600px)'].smallButtonStyle }} />
              </div>
              <div onClick={() => handlemessage1(trips)} style={{ display: 'inline-block' }}>
                <img src={dislike} alt='Incorrect' style={{ ...smallButtonStyle1, ...responsiveStyle['@media (max-width: 600px)'].smallButtonStyle1 }} />
              </div>
            </div>
              </div>
             

            </div>
          ))}
        </div>
      ) : (
        <p>No trip details available</p>
      )}
    </div>
  );
};

export default Busdetails;
