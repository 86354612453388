import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Details from './Details';
import axios from 'axios';
import '../Styles/details.css';
import eyes from '../Images/eyes.jpeg';
import like from '../Images/like.png';
import dislike from '../Images/dislike.png';
import { useNavigate } from 'react-router-dom';
const Stopdetails = () => {
  const location = useLocation();
  const { from } = location.state;
  const [tripDetails, setTripDetails] = useState([]);
const nav = useNavigate();
  const divStyle = {
    textAlign: 'center',

    fontFamily: 'Comfortaa, sans-serif', // Specify Comfortaa as the font
  };

  useEffect(() => {
    // Define the URL with from.value and to.value
    const apiUrl = `https://api.busparrot.com/getstoptrips.php?stopid=${from.value}`;

    const fetchData = async () => {
      try {
        // Fetch trip details using Axios and await the response
        const response = await axios.get(apiUrl);
      
        // Set the trip details in the state
        setTripDetails(response.data.trips);
        console.log('Fetched Details:', response.data.trips);
       console.log(tripDetails,'hhh')
      } catch (error) {
        console.error('Error fetching trip details:', error);
      }
    };

    fetchData();
  }, [from.value]);
  console.log(from.value,'value');
  console.log(from.label,'label');
 
  const handleclick = (trip) => {

    nav('/stopbusdetails', { state: { route: trip.Headsign, bus: trip.Bus ,id:trip.TripID} })
  }

  const handlemessage = async (trip) => {
    const ipApiResponse = await axios.get('https://api.ipify.org?format=json');
    const userIpAddress = ipApiResponse.data.ip;
    const currentTime = new Date(); 
    const feedbackData = {
      appname: 'Bus',
      category: 'Stop',
      infoid: "tripid : "+trip.TripID,
      customerid: 'Not defined',
      ipaddress: userIpAddress,
      feedback: 'Correct',
      message: 'Not defined',
      time: currentTime.toLocaleString(),
    }

    const apiUrl = 'https://yatraparrot.com/feedback/customer_feedback.php';
    const urlWithParams = `${apiUrl}?appname=${feedbackData.appname}&category=${feedbackData.category}&infoid=${feedbackData.infoid}&customerid=${feedbackData.customerid}&ipaddress=${feedbackData.ipaddress}&feedback=${feedbackData.feedback}&message=${feedbackData.message}&time=${feedbackData.time}`;
    
  
    try {
      const response = await fetch( urlWithParams, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // Add any other headers as needed
        },
        body: JSON.stringify({
          // Add your data here
          
          // Add other data fields as needed
        }),
      });
  
      console.log(response, "feedback response");


      if (response.status === 200) {
        // Display an alert when feedback is successfully posted
        window.alert('Feedback posted successfully!');
      } else {
        // Display an alert for any other response status
        window.alert('Failed to post feedback. Please try again.');
      }
      // Handle the response accordingly
    } catch (error) {
      console.error('Error sending feedback:', error);
    }
  };
  const smallButtonStyle = {
    width: '40px', // Adjust the width value as needed
    
  };

 
  

  const handlemessage1 = async (trip) => {
    const ipApiResponse = await axios.get('https://api.ipify.org?format=json');
    const userIpAddress = ipApiResponse.data.ip;
    const currentTime = new Date();
  
    // Show a prompt to enter the message
    const userMessage = window.prompt('Enter your feedback message:');
  
    // Check if the user clicked cancel
    if (userMessage === null) {
      window.alert('Feedback submission canceled.');
      return;
    }
  
    const feedbackData = {
      appname: 'Bus',
      category: 'Stop',
      infoid: 'tripid' + trip.TripID,
      customerid: 'Not defined',
      ipaddress: userIpAddress,
      feedback: 'InCorrect',
      message: userMessage || 'No Messages', // Use user input or 'No Messages' if empty
      time: currentTime.toLocaleString(),
    };
  
    const apiUrl = 'https://yatraparrot.com/feedback/customer_feedback.php';
    const urlWithParams = `${apiUrl}?appname=${feedbackData.appname}&category=${feedbackData.category}&infoid=${feedbackData.infoid}&customerid=${feedbackData.customerid}&ipaddress=${feedbackData.ipaddress}&feedback=${feedbackData.feedback}&message=${feedbackData.message}&time=${feedbackData.time}`;
  
    try {
      const response = await fetch(urlWithParams, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // Add any other headers as needed
        },
        body: JSON.stringify({
          // Add your data here
          // Add other data fields as needed
        }),
      });
  
      console.log(response, 'feedback response');
  
      if (response.status === 200) {
        // Display an alert when feedback is successfully posted
        window.alert('Feedback posted successfully!');
      } else {
        // Display an alert for any other response status
        window.alert('Failed to post feedback. Please try again.');
      }
      // Handle the response accordingly
    } catch (error) {
      console.error('Error sending feedback:', error);
    }
  };
  
  const smallButtonStyle1 = {
    width: '30px', // Adjust the width value as needed
   
    
    // backgroundColor: ' #f72e42',
    marginLeft: '10px',
    marginTop:'8px'
  };
  const arrivalStyle = {
    color: 'green',
    fontWeight: 'bold',
    fontSize: '3em',
    // backgroundColor: '#ebd69d', // Highlight background
    padding: '5px',
    borderRadius: '5px',
  };

  const departureStyle = {
    color: 'red',
    fontWeight: 'bold',
    fontSize: '3em',
    // backgroundColor: '#ebd69d', // Highlight background
    padding: '5px',
    borderRadius: '5px',
  };

  
  // Media queries for responsiveness
  const responsiveStyle = {
    '@media (max-width: 600px)': {
      arrivalStyle: {
        fontSize: '1em',
      },
      departureStyle: {
        fontSize: '1em',
      },
      smallButtonStyle: {
        width: '30px',
      },
      smallButtonStyle1: {
        width: '30px',
      },
    },
  };

  return (
    <div className='bg-body-tertiary mb-3'>
      <Details />
      <div style={divStyle} className="bg1">
        <h6 className='hstyle'>
        <b>  You are at {from.label}</b>
        </h6>
       
      </div>
      
    
  {/* {tripDetails && tripDetails.success === 1 && (
    <div className="trip-list">
      {tripDetails.result.map((trips) => (
        <div className='det' key={trips.TripID}>
          <img className='image' src={eyes} alt="BusParrot" />
          <div className="trip-details">
            <p> <b>{trips.Arrival} Arrival</b></p>
            <h6><b> {trips.Headsign}</b></h6>
            <span className='sp' onClick={() => handleclick(trips)}> <b>{trips.Bus}</b></span>
            <p><b>{trips.departure} Departure</b></p>
          </div>
        </div>
      ))}
    </div>
  )} */}
  {tripDetails && Array.isArray(tripDetails) && tripDetails.length > 0 ? (
  <div className="trip-list">
    {tripDetails.map((trips) => (
  
  <div className='det' key={trips.TripID}>
  <div className='trip-details'>
    <br />
        {/* Render your trip details here */}
        <img className='image' src={eyes} alt='BusParrot' />
                <p style={{ ...arrivalStyle, ...responsiveStyle['@media (max-width: 600px)'].arrivalStyle }}>
  <span style={{ fontSize: '2em', fontWeight: 'bold', color: 'green' }}>{trips.Arrival}</span> <b>Arrival</b>
</p>

                <h6><b>{trips.Headsign}</b></h6>
                <span className='sp' onClick={() => handleclick(trips)}><b>{trips.Bus}</b></span>
               
                <p style={{ ...departureStyle, ...responsiveStyle['@media (max-width: 600px)'].departureStyle }}>
                <span style={{ fontSize: '2em', fontWeight: 'bold', color: 'red' }}>{trips.departure} </span> <b>Departure</b></p>
                <div className='container-style'>
              <h7><b>Was this Correct?</b></h7>
              <div onClick={() => handlemessage(trips)} style={{ display: 'inline-block' }}>
                <img src={like} alt='Correct' style={{ ...smallButtonStyle, ...responsiveStyle['@media (max-width: 600px)'].smallButtonStyle }} />
              </div>
              <div onClick={() => handlemessage1(trips)} style={{ display: 'inline-block' }}>
                <img src={dislike} alt='Incorrect' style={{ ...smallButtonStyle1, ...responsiveStyle['@media (max-width: 600px)'].smallButtonStyle1 }} />
              </div>
            </div>

            </div>
            </div>
    ))}
   
  </div>
) : (
  <p>No trip details available</p>
)}

</div>

  );
  
 
};


export default Stopdetails;
