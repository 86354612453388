import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import '../Styles/details.css';
import like from '../Images/like.png';
import dislike from '../Images/dislike.png';
import greenbus from '../Images/greenbus.png';

const Stopbusdetails = () => {
  const location = useLocation();
  const { route, bus, id } = location.state;
  const [tripDetails, setTripDetails] = useState([]);
  const [currentTime, setCurrentTime] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiUrl = `https://api.busparrot.com/gettripdetails.php?tripid=${id}`;
        const response = await axios.get(apiUrl);
        setTripDetails(response.data.result);
      } catch (error) {
        console.error('Error fetching trip details:', error);
      }
    };

    fetchData();

    const now = new Date();
    const formattedTime = now.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });
    setCurrentTime(formattedTime);
  }, [id]);

  const handlemessage = async (trip) => {
    // Implement your feedback handling logic here
  };

  const handlemessage1 = async (trip) => {
    // Implement your feedback handling logic here
  };

  const smallButtonStyle = {
    width: '40px',
  };

  const smallButtonStyle1 = {
    width: '30px',
    marginLeft: '10px',
    marginTop: '8px',
  };

  let foundFirstRedLine = false;

  return (
    <div className='bg-body-tertiary mb-3'>
      <div style={{ textAlign: 'center', fontFamily: 'Comfortaa, sans-serif' }}>
        <h6 className='hstyle'>
          <b>{route}</b>
        </h6>
        <h6 className='hstyle1'>
          <b>{bus}</b>
        </h6>
      </div>
      <div>
        {tripDetails.map((trips, index) => {
          const arrivalTime = trips.arrival_time;
          const isRedLine = arrivalTime >= currentTime;

          let showGreenBus = false;
          if (isRedLine && !foundFirstRedLine) {
            foundFirstRedLine = true;
            showGreenBus = true;
          }

          return (
            <div className="trip-details" key={trips.TripID} style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{
                borderLeft: arrivalTime < currentTime ? '8px solid green' : '8px solid red',
                height: '100%',
                flex: 1,
                paddingLeft: '10px',
                position: 'relative',
              }}>
                <div style={{
                  width: '10px',
                  height: '10px',
                  borderRadius: '20%',
                  backgroundColor: arrivalTime < currentTime ? 'green' : 'transparent',
                  marginRight: '5px',
                  position: 'relative',
                }}>
                  {showGreenBus && (
                    <img src={greenbus} alt="Green Bus" style={{ position: 'absolute', left: '-20px', top: '-10px', width: '20px', height: '20px' }} />
                  )}
                </div>

                <div style={{ backgroundColor: '#e3f2da', padding: '10px', borderRadius: '5px' }}>
                  <p><b>{trips.arrival_time} Arrival</b></p>
                  <h6><b>{trips.stop_name}</b></h6>
                  <p><b>{trips.departure_time} Departure</b></p>

                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div onClick={() => handlemessage(trips)}>
                      <h7>Was this Correct?</h7>
                      <img src={like} alt="Correct" style={smallButtonStyle} />
                    </div>
                    <div onClick={() => handlemessage1(trips)}>
                      <img src={dislike} alt="Incorrect" style={smallButtonStyle1} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Stopbusdetails;
