import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import axios from 'axios';
import Navpage1 from './Navpage1';
import facebook from '../Images/facebook.png';
import linkedin from '../Images/linkedin.png';
import twitter from '../Images/twitter.png';
import youtube from '../Images/youtube.png';
import { useNavigate } from 'react-router-dom';
import '../Styles/navpage.css';
import Map from './Map';
import gmap from '../Images/gmap.png';
import Cookies from 'js-cookie';
import sm from '../Images/streetview.png';
import Footer from './Footer';
const Trainpage = () => {
  const [options, setOptions] = useState([]);
  const [lastLocationLatitude1, setLastLocationLatitude1] = useState(null);
  const [lastLocationLongitude1, setLastLocationLongitude1] = useState(null);
  const [ipLocationLatitude, setIpLocationLatitude] = useState(null);
  const [ipLocationLongitude, setIpLocationLongitude] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [nearestStations, setNearestStations] = useState([]);
  const [nearestStops, setNearestStops] = useState([]);
  const [defaultSelectValue, setDefaultSelectValue] = useState(null);

  const [iframeSrc, setIframeSrc] = useState('');
  const nav = useNavigate();

  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: '300px',
    }),
    option: (provided, state) => ({
      ...provided,
      color: 'black',
    }),
  };

  const loadOptions = async (inputValue) => {
    try {
      const response = await axios.get(`https://api.busparrot.com/searchtrainstation.php?term=${inputValue}`);
      const stopNames = response.data.map((item) => ({
        label: item.value,
        value: item.id,
      }));
      setOptions(stopNames);
    } catch (error) {
      console.error('Error fetching stop names:', error);
    }
  };

  const loadTrainStations = async (lat, long, radius) => {
    try {
      const response = await axios.get(`https://api.busparrot.com/gettrainstations.php?lat=${lat}&lng=${long}&radius=${radius}`);
      const stationsWithRoundedDistance = response.data.stations.map((station) => ({
        ...station,
        distance: parseFloat(station.distance).toFixed(1),
      }));
      setNearestStations(stationsWithRoundedDistance);
    } catch (error) {
      console.error('Error fetching train stations:', error);
    }
  };

  const fetchLocationByIP = async () => {
    try {
      const ipApiResponse = await axios.get('https://api.ipify.org?format=json');
      const userIpAddress = ipApiResponse.data.ip;

      const locationApiResponse = await axios.get(`https://ipapi.co/${userIpAddress}/json/`);
      const [latitude, longitude] = [locationApiResponse.data.latitude, locationApiResponse.data.longitude];

      setIpLocationLatitude(latitude);
      setIpLocationLongitude(longitude);

      const radius = 10;
      loadTrainStations(latitude, longitude, radius);
    } catch (error) {
      console.error('Error fetching location by IP:', error);
    }
  };

  useEffect(() => {
    const lastLocationLatitude = Cookies.get('latitude');
    const lastLocationLongitude = Cookies.get('longitude');

    if (lastLocationLatitude && lastLocationLongitude) {
      setLastLocationLatitude1(lastLocationLatitude);
      setLastLocationLongitude1(lastLocationLongitude);
      const radius = 10;
      loadTrainStations(lastLocationLatitude, lastLocationLongitude, radius);
    } else {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            setLastLocationLatitude1(latitude);
            setLastLocationLongitude1(longitude);
            const radius = 10;
            loadTrainStations(latitude, longitude, radius);

            document.cookie = `lastLocationLatitude=${latitude}`;
            document.cookie = `lastLocationLongitude=${longitude}`;
          },
          (error) => {
            console.error('Error getting current location:', error);
          }
        );
      } else {
        fetchLocationByIP();
      }
    }
  }, []);

  const handleInputChange = (inputValue) => {
    loadOptions(inputValue);
  };

  const handleStopClick = (stationname, stationid) => {
    const src = `https://erail.in/station-live/${stationname}-${stationid}`;
    setIframeSrc(src);
  };

  const handleSearch = () => {
    if (selectedOption) {
      // Use a regular expression to extract the parts inside and outside the parentheses
      const match = selectedOption.label.match(/^(.+?)\s*\((.+?)\)$/);
      
      if (match) {
        const [_, name, code] = match; // Extract the name and code
        const src = `https://erail.in/station-live/${name.trim()}-${code.trim()}`;
        setIframeSrc(src); // Set the iframe source
      } else {
        console.log('The selected option format is incorrect.');
      }
    } else {
      console.log('Please select a train station to search for details.');
    }
  };
  
    // if (selectedOption) {
    //   nav('/traindetails', { state: { train: selectedOption } });
    // } else {
    //   console.log('Please select a train station to search for details.');
    // }
  // };

  const handleGetDirections = (station) => {
    const destinationLat = station.stop_lat;
    const destinationLng = station.stop_lon;
    const directionsURL = `https://www.google.com/maps/dir/${lastLocationLatitude1},${lastLocationLongitude1}/${destinationLat},${destinationLng}`;
    window.open(directionsURL);
  };

  const handleGetDirections1 = (station) => {
    const destinationLat = station.stop_lat;
    const destinationLng = station.stop_lon;
    const streetViewURL = `https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=${destinationLat},${destinationLng}&heading=0&pitch=0`;
    window.open(streetViewURL);
  };

  const renderPlaces = () => {
    if (nearestStations.length === 0) {
      return (
        <div>
          <p style={{ color: 'red', fontSize: '20px', marginLeft: '30px' }}>
            The public transport system in your area is not digitized yet. Please contact busparrot through your local body to digitize.
          </p>
        </div>
      );
    }
  };

  
const getCurrentLocation = () => {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        const apiUrl = `https://api.busparrot.com/getneareststops.php?lat=${latitude}&lon=${longitude}&radius=2`;
        console.log(apiUrl, "kkkk");

        fetch(apiUrl)
          .then((response) => response.json())
          .then((data) => {
            console.log('API Response:', data.stops[0]);

            if (data.stops.length > 0) {
              const firstStop = data.stops[0];

              setDefaultSelectValue({
                value: firstStop.stop_id,
                label: firstStop.stop_name,
              });
            }
          })
          
          .catch((error) => {
            console.error('Error fetching data:', error);
          });
          fetchNearestStops(latitude,longitude );
      },
      (error) => {
        console.error('Error getting current position:', error);
        // If there's an error getting the current position, try to retrieve from cookies
        const latitudeFromCookie = Cookies.get('latitude');
        const longitudeFromCookie = Cookies.get('longitude');

        if (latitudeFromCookie && longitudeFromCookie) {
          const apiUrl = `https://api.busparrot.com/getneareststops.php?lat=${latitudeFromCookie}&lon=${longitudeFromCookie}&radius=2`;

          fetch(apiUrl)
            .then((response) => response.json())
            .then((data) => {
              console.log('API Response (from cookies):', data.stops[0]);

              if (data.stops.length > 0) {
                const firstStop = data.stops[0];

                setDefaultSelectValue({
                  value: firstStop.stop_id,
                  label: firstStop.stop_name,
                });
              }
            })
            .catch((error) => {
              console.error('Error fetching data from cookies:', error);
            });
            fetchNearestStops(latitudeFromCookie, longitudeFromCookie);
        }
      }
    );
  
  } else {
    console.error('Geolocation is not available in your browser');
    // If geolocation is not available, try to retrieve from cookies
    const latitudeFromCookie = Cookies.get('latitude');
    const longitudeFromCookie = Cookies.get('longitude');

    if (latitudeFromCookie && longitudeFromCookie) {
      const apiUrl = `https://api.busparrot.com/getneareststops.php?lat=${latitudeFromCookie}&lon=${longitudeFromCookie}&radius=2`;

      fetch(apiUrl)
        .then((response) => response.json())
        .then((data) => {
          console.log('API Response (from cookies):', data.stops[0]);

          if (data.stops.length > 0) {
            const firstStop = data.stops[0];

            setDefaultSelectValue({
              value: firstStop.stop_id,
              label: firstStop.stop_name,
            });
          }
        })
        .catch((error) => {
          console.error('Error fetching data from cookies:', error);
        });
         fetchNearestStops(latitudeFromCookie, longitudeFromCookie);
    }
  }
};


const fetchNearestStops = async (latitude, longitude) => {
  try {
    console.log(latitude,longitude,"latlon");
    const apiUrl = `https://api.busparrot.com/getneareststops.php?lat=${latitude}&lon=${longitude}&radius=2`;
    console.log(apiUrl,'stops')
    const response = await axios.get(apiUrl);

    console.log('API Response:', response);

    const nearestStopsData = response.data.stops.map((stop) => ({
      ID: stop.stop_id,
      stop_name: stop.stop_name,
      distance: stop.distance,
      lat: stop.stop_lat,
      lon: stop.stop_lon,
      lbcode:stop.stop_localbodycode,
      page:'train',
    }));

    setNearestStops(nearestStopsData);
    setDefaultSelectValue({
      value: nearestStopsData[0].ID,
      label: nearestStopsData[0].stop_name,
    });
  } catch (error) {
    console.error('Error fetching nearest stops data:', error);
  }
};


useEffect(() => {
  getCurrentLocation(); // Fetch nearest stops when the component mounts
}, []);


  return (
    <div>
      <Navpage1 />
      <br />
      <div className="nearest-stations">
        {renderPlaces()}
        {nearestStations.length > 0 && (
          <div style={{ textAlign: 'center' }}>
            <h2>Nearest Railway Stations</h2>
            <table style={{ textAlign: 'center' }} className="table-container container-fluid">
              <thead>
                <tr>
                  <th>Stop Name</th>
                  <th>Distance (km)</th>
                </tr>
              </thead>
              <tbody>
                {nearestStations.map((station) => (
                  <tr key={station.ID}>
                    <td>
                      <span
                        onClick={() => handleStopClick(station.stop_name, station.stop_id)}
                        style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}
                      >
                        {station.stop_name}
                      </span>
                      <br />
                      {/\d/.test(station.stop_desc) && (
                        <>
                          Call:{' '}
                          <a href={`tel:${station.stop_desc.replace(/\D/g, '')}`}>
                            {station.stop_desc.replace(/\D/g, '')}
                          </a>
                        </>
                      )}
                    </td>
                    <td>
                      {station.distance} Km away
                      <a onClick={() => handleGetDirections(station)}>
                        <img
                          src={gmap}
                          alt="Google Maps Icon"
                          style={{ width: '20px', height: '20px', marginRight: '10px' }}
                        />
                      </a>
                      <span className="go">Go</span>
                      <a onClick={() => handleGetDirections1(station)}>
                        <img
                          src={sm}
                          alt="Street View Icon"
                          style={{ width: '35px', height: '30px', marginRight: '10px', marginLeft: '10px' }}
                        />
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
      <div className="subbody container-fluid">
        <br />
        <div className="trip-container">
          <div className="input-container" style={{ marginBottom: '10px' }}>
            <h2>Search Railway Station</h2>
            <br />
            <Select
              value={selectedOption}
              onChange={setSelectedOption}
              onInputChange={handleInputChange}
              options={options}
              placeholder="Search trains"
              styles={customStyles}
            />
          </div>
          <div className="input-container" style={{ marginBottom: '10px' }}>
            <button className="search-button" onClick={handleSearch}>
              Search
            </button>
          </div>
      
        </div>
        {iframeSrc && (
        <div className="iframe-container">
          <iframe
            src={iframeSrc}
            className="responsive-iframe"
            height="600px"
            style={{ border: 'none' }}
            title="Station Live Status"
          ></iframe>
        </div>
      )}
      </div>
     
     
<div>
  {nearestStops.length > 0 && (
    <div>
      <Footer nearestStops={nearestStops} />
    </div>
  )}
</div>

      {/* <div className="mapstyle">
        <Map />
      </div>
      <br />
      <div style={{ textAlign: 'center', marginBottom: '750px' }}>
        <a href="https://m.facebook.com/100063656121677/">
          <img className="imagesocial" style={{ width: '30px' }} src={facebook} alt="Facebook" />
        </a>
        <a href="https://twitter.com/BusParrot?s=08">
          <img className="imagesocial" style={{ width: '30px' }} src={twitter} alt="Twitter" />
        </a>
        <a href="https://youtube.com/@user-mk1uk6sq2t">
          <img className="imagesocial" style={{ width: '30px' }} src={youtube} alt="YouTube" />
        </a>
        <a href="https://www.linkedin.com/company/busparrot/">
          <img className="imagesocial" style={{ width: '30px' }} src={linkedin} alt="LinkedIn" />
        </a>
      </div> */}
    </div>
  );
};

export default Trainpage;
